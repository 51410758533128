import { useState } from "react";
import { cookieGetter } from "../../utils/HelperFunction";
import axios from "axios";
import useToast from "../Toast";

const UsePieChartData = () => {
  const appIdToken = cookieGetter("appIdToken");
  const [loading, setLoading] = useState(false);
  const [pieChartData, setPieChartData] = useState(null);
  const notify = useToast();

  const pieChartApiCall = async (pieChartObject) => {
    setLoading(true);
    try {
      const response = await axios({
        method: "post",
        url: `https://mkdpi-backend-234023401939.us-central1.run.app/dashboard/detailed`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${appIdToken}`,
        },
        data: pieChartObject,
      });
      console.log("API response:", response);

      if (response.status !== 200) {
        notify("There is some issue please try again", "error");
        setPieChartData({});
      } else {
        setPieChartData(response?.data);
      }
    } catch (error) {
      notify("There is some issue in fetching data please try again", "error");
      console.log("There is some issue please try again : ", error);
    } finally {
      setLoading(false);
    }
  };
  return {
    pieChartApiCall,
    loading,
    setLoading,
    pieChartData,
  };
};

export default UsePieChartData;
