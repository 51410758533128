import { useState } from "react";
import { cookieGetter } from "../../utils/HelperFunction";
import axios from "axios";
import useToast from "../Toast";

const UseHistogramData = () => {
  const appIdToken = cookieGetter("appIdToken");
  const [loading, setLoading] = useState(false);
  const [histogramData, setHistogramData] = useState(null);
  const notify = useToast();

  const histogramApiCall = async (histogramObject) => {
    setLoading(true);
    try {
      const response = await axios({
        method: "post",
        url: `https://mkdpi-backend-234023401939.us-central1.run.app/dashboard/histogram`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${appIdToken}`,
        },
        data: histogramObject,
      });

      if (response.status !== 200) {
        notify("There is some issue please try again", "error");
        setHistogramData({});
      } else {
        setHistogramData(response?.data);
      }
    } catch (error) {
      notify("There is some issue in fetching data please try again", "error");
      console.log("There is some issue please try again : ", error);
    } finally {
      setLoading(false);
    }
  };
  return {
    histogramApiCall,
    loading,
    setLoading,
    histogramData,
  };
};

export default UseHistogramData;
