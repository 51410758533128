import { useState } from "react";
import { cookieGetter } from "../../utils/HelperFunction";
import axios from "axios";
import useToast from "../Toast";

const UseDashboardData = () => {
  const appIdToken = cookieGetter("appIdToken");
  const [loading, setLoading] = useState(false);
  const [dashBoardData, setDashBoardData] = useState(null);
  const notify = useToast();

  const dashboardApiCall = async (finalObject) => {
    try {
      const response = await axios({
        method: "post",
        url: `https://mkdpi-backend-234023401939.us-central1.run.app/dashboard`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${appIdToken}`,
        },
        data: finalObject,
      });

      if (response.status !== 200) {
        notify("There is some issue please try again", "error");
        setDashBoardData({});
      } else {
        setDashBoardData(response?.data)
        console.log("done dashbvoard object", response?.data);
      }
    } catch (error) {
      notify("There is some issue in fetching data please try again", "error");
      console.log("There is some issue please try again : ", error);
    } finally {
      setLoading(false);
    }
  };
  return { dashboardApiCall, loading, setLoading, dashBoardData };
};

export default UseDashboardData;
