import { useState } from "react";
import axios from "axios";
import { cookieGetter } from "../../utils/HelperFunction";

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
};
const UseChatBot = () => {
    const [selectedAdvertiserIds, setSelectedAdvertiserIds] = useState("");
    const [sessionId, setSessionId] = useState(localStorage.getItem("sessionId"));
    const [loadingAdvertisers, setLoadingAdvertisers] = useState(false);
    const [loadingSession, setLoadingSession] = useState(false);
    const [loadingQuestion, setLoadingQuestion] = useState(false);
    const [error, setError] = useState(null);
    const appIdToken = cookieGetter("appIdToken");

    const initiateChatSession = async (advertiserIds) => {
        setLoadingSession(true);
        setError(null);
        try {
            const email = getCookie("email");
            const url = `https://us-central1-credible-glow-442213-j8.cloudfunctions.net/text-to-sql-chatbot?advertiserId=${advertiserIds}&email=${email}`;

            const response = await axios.get(url, {
                headers: {
                    // 'Authorization': `Bearer ${appIdToken}`,
                    'Content-Type': 'application/json',
                },
            });
            const { sessionId } = response?.data;
            localStorage.setItem("sessionId", sessionId);
            setSessionId(sessionId);
            setError(null);
        } catch (err) {
            setError("Error initiating chat session");
        } finally {
            setLoadingSession(false);
        }
    };

    const submitQuestion = async (question) => {
        if (!sessionId) return;
        setLoadingQuestion(true);
        setError(null);
        try {
            const url = `https://us-central1-credible-glow-442213-j8.cloudfunctions.net/text-to-sql-chatbot?sessionId=${sessionId}&question=${question}`;
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setError(null);
            return response?.data;
        } catch (err) {
            setError("Error fetching chatbot response");
        } finally {
            setLoadingQuestion(false); 
        }
    };

    return {
        selectedAdvertiserIds,
        setSelectedAdvertiserIds,
        sessionId,
        loadingAdvertisers,
        loadingSession,
        loadingQuestion,
        error,
        initiateChatSession,
        submitQuestion,
    };
};

export default UseChatBot;